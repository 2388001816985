import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Check, Clear, Delete, Edit, Visibility } from "@material-ui/icons";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-hijri";
import swal from "sweetalert";
import { DeleteCelengan, GetCelenganList } from "../../config/redux/services";
function TableCelengan(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.celengan);

  if (state.deleteCelengan) {
    swal({
      title: "Berhasil",
      text: "Data Celengan Telah Dihapus",
      icon: "success",
      timer: 2000,
      button: false,
    }).then(() => {
      dispatch(GetCelenganList(moment().iYear()));
    });
  }
  const rows = props.dataRow;
  const columns = [
    {
      field: "id_peserta",
      headerName: "ID",
      flex: 0.3,
      renderCell: (params) => {
        return params.row.id_peserta;
      },
    },
    {
      field: "id_peserta_peserta",
      headerName: "Nama",
      flex: 0.8,
      valueGetter: (params, row) => {
        return row.id_peserta_peserta.name;
      },
    },
    {
      field: "amount",
      headerName: "Jumlah",
      flex: 0.8,
      editable: true,
      renderCell: (params) => {
        return (
          "Rp. " +
          params.row.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        );
      },
    },
    {
      field: "is_money_received",
      headerName: "Diterima",
      flex: 0.5,
      editable: true,
      renderCell: (params) => {
        return params.row.is_money_received.toString() === "1" ? (
          <Check style={{ color: "green" }} />
        ) : (
          <Clear style={{ color: "red" }} />
        );
      },
    },
    {
      field: "is_money_box_sent",
      headerName: "Diberikan",
      flex: 0.5,
      renderCell: (params) => {
        return params.row.is_money_box_sent.toString() === "1" ? (
          <Check style={{ color: "green" }} />
        ) : (
          <Clear style={{ color: "red" }} />
        );
      },
    },
    {
      field: "year_hijriah",
      headerName: "Tahun",
      flex: 0.4,
      renderCell: (params) => {
        return params.row.year_hijriah + " H";
      },
    },
    {
      field: "date_money_received",
      headerName: "Tanggal Diterima",
      flex: 0.7,
      renderCell: (params) => {
        return params.row.date_money_received;
      },
    },
    {
      field: "date_money_box_sent",
      headerName: "Tanggal Diberikan",
      flex: 0.7,
      renderCell: (params) => {
        return params.row.date_money_box_sent;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div>
            <Visibility
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => {
                history.push("/admin/celengan/lihat/" + params.row.id);
              }}
            />
            <Edit
              style={{ color: "orange", cursor: "pointer" }}
              onClick={() => {
                history.push("/admin/celengan/ubah/" + params.row.id);
              }}
            />
            <Delete
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                dispatch(DeleteCelengan(params.row.id));
              }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <DataGrid
      loading={state.loading}
      rows={rows}
      columns={columns}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      autoHeight
      pageSize={10}
    />
  );
}

export default TableCelengan;
