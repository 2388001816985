import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Check, Clear, Delete, Edit, Visibility } from "@material-ui/icons";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { DeletePeserta, GetPesertaList } from "../../config/redux/services";
import { resetErrorData } from "../../config/redux/features/peserta/pesertaSlice";
function TablePeserta(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.peserta);

  if (state.deletePeserta) {
    swal({
      title: "Berhasil",
      text: "Data Peserta Telah Dihapus",
      icon: "success",
      timer: 2000,
      button: false,
    }).then(() => {
      dispatch(GetPesertaList());
    });
  } else if (state.errorData) {
    swal({
      title: "Gagal",
      text: "Data Peserta Gagal DIhapus",
      icon: "error",
      timer: 2000,
      button: false,
    });
    dispatch(resetErrorData());
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.4,
    },
    { field: "name", headerName: "Nama", flex: 0.6 },
    {
      field: "is_person",
      headerName: "Warga",
      renderCell: (params) => {
        return params.row.is_person.toString() === "1" ? (
          <Check style={{ color: "green" }} />
        ) : (
          <Clear style={{ color: "red" }} />
        );
      },
    },
    {
      field: "service_money",
      headerName: "Celengan",
      flex: 0.5,
      renderCell: (params) => {
        return params.row.service_money.toString() === "1" ? (
          <Check style={{ color: "green" }} />
        ) : (
          <Clear style={{ color: "red" }} />
        );
      },
    },
    {
      field: "service_zakat_sent",
      headerName: "Jatah Zakat",
      flex: 0.6,
      renderCell: (params) => {
        return params.row.service_zakat_sent.toString() === "1" ? (
          <Check style={{ color: "green" }} />
        ) : (
          <Clear style={{ color: "red" }} />
        );
      },
    },
    {
      field: "service_zakat_received",
      headerName: "Zakat",
      flex: 0.4,
      renderCell: (params) => {
        return params.row.service_zakat_received.toString() === "1" ? (
          <Check style={{ color: "green" }} />
        ) : (
          <Clear style={{ color: "red" }} />
        );
      },
    },
    {
      field: "service_qurban_sent",
      headerName: "Jatah Qurban",
      flex: 0.6,
      renderCell: (params) => {
        return params.row.service_qurban_sent.toString() === "1" ? (
          <Check style={{ color: "green" }} />
        ) : (
          <Clear style={{ color: "red" }} />
        );
      },
    },
    {
      field: "service_qurban_received",
      headerName: "Qurban",
      flex: 0.5,
      renderCell: (params) => {
        return params.row.service_qurban_received.toString() === "1" ? (
          <Check style={{ color: "green" }} />
        ) : (
          <Clear style={{ color: "red" }} />
        );
      },
    },
    {
      field: "location",
      headerName: "Lokasi",
      flex: 0.5,
      renderCell: (params) => {
        return params.row.location;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div>
            <Visibility
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                if (!state.loadingDelete) {
                  history.push("/admin/peserta/lihat/" + params.row.id);
                }
              }}
            />
            <Edit
              style={{ color: "orange", cursor: "pointer" }}
              onClick={() => {
                if (!state.loadingDelete) {
                  history.push("/admin/peserta/ubah/" + params.row.id);
                }
              }}
            />
            <Delete
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                if (!state.loadingDelete) {
                  dispatch(DeletePeserta(params.row.id));
                }
              }}
            />
          </div>
        );
      },
    },
  ];
  const rows = props.dataRow;
  return (
    <div style={{ minHeight: "500px", width: "100%" }}>
      <DataGrid
        loading={state.loading}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        style={{ textAlign: "center" }}
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={10}
      />
    </div>
  );
}

export default TablePeserta;
