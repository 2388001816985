import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Check, Clear, Delete, Edit, Visibility } from "@material-ui/icons";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-hijri";
import swal from "sweetalert";
import {
  DeleteZakatFitrahSent,
  GetZakatFitrahSentList,
} from "../../config/redux/services";
function TableZakatFitrahSent(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.zakatFitrahSent);

  if (state.deleteZakatFitrahSent) {
    swal({
      title: "Berhasil",
      text: "Data Penerima Zakat Fitrah Telah Dihapus",
      icon: "success",
      timer: 2000,
      button: false,
    }).then(() => {
      dispatch(GetZakatFitrahSentList(moment().iYear()));
    });
  }

  const columns = [
    {
      field: "id_peserta",
      headerName: "ID",
      flex: 0.3,
      renderCell: (params) => {
        return params.row.id_peserta;
      },
    },
    {
      field: "id_peserta_peserta",
      headerName: "Nama",
      flex: 0.8,
      valueGetter: (params, row) => {
        return row.id_peserta_peserta.name;
      },
    },
    {
      field: "amount_sent",
      headerName: "Jatah ",
      flex: 0.4,
      editable: true,
      valueGetter: (params, row) => {
        return row.amount_sent + " Liter";
      },
    },
    {
      field: "is_zakat_sent",
      headerName: "Status Diberikan",
      flex: 0.7,
      renderCell: (params) => {
        return params.row.is_zakat_sent.toString() === "1" ? (
          <Check style={{ color: "green" }} />
        ) : (
          <Clear style={{ color: "red" }} />
        );
      },
    },
    {
      field: "year_hijriah",
      headerName: "Tahun",
      flex: 0.4,
      renderCell: (params) => {
        return params.row.year_hijriah + " H";
      },
    },
    {
      field: "date_zakat_sent",
      headerName: "Tanggal",
      flex: 0.7,
      renderCell: (params) => {
        return params.row.date_zakat_sent;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div>
            <Visibility
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => {
                history.push(
                  "/admin/penerimazakatfitrah/lihat/" + params.row.id
                );
              }}
            />
            <Edit
              style={{ color: "orange", cursor: "pointer" }}
              onClick={() => {
                history.push(
                  "/admin/penerimazakatfitrah/ubah/" + params.row.id
                );
              }}
            />
            <Delete
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                dispatch(DeleteZakatFitrahSent(params.row.id));
              }}
            />
          </div>
        );
      },
    },
  ];
  const rows = props.dataRow;
  return (
    <div>
      <DataGrid
        loading={state.loading}
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={20}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
}

export default TableZakatFitrahSent;
