import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import { Breadcrumbs, Grid } from "@material-ui/core";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDetailCelengan } from "../../../config/redux/action";
import AdminTemplate from "../../templates/AdminTemplate";
import FormCelengan from "../../../components/moleculs/FormCelengan";

function EditCelengan() {
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (params.id) {
      dispatch(getDetailCelengan(params.id));
    }
  }, [dispatch, params.id]);
  return (
    <AdminTemplate>
      <div>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <NavLink color="secondary" to="/admin/celengan">
                  Peserta Celengan Dana Sosial
                </NavLink>
                <NavLink color="secondary" to="/admin/celengan/ubah/:id">
                  Ubah Peserta Celengan Dana Sosial
                </NavLink>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <FormCelengan />
            </Grid>
          </Grid>
        </Container>
      </div>
    </AdminTemplate>
  );
}

export default EditCelengan;
