import React from 'react'

function ErrorPage() {
    return (
        <div>
            Error Page
        </div>
    )
}

export default ErrorPage
