import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Check, Clear, Delete, Edit, Visibility } from "@material-ui/icons";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-hijri";
import swal from "sweetalert";
import {
  DeleteZakatFitrahReceived,
  GetZakatFitrahReceivedList,
} from "../../config/redux/services";
function TableZakatFitrah(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.zakatFitrahReceived);

  if (state.deleteZakatFitrahReceived) {
    swal({
      title: "Berhasil",
      text: "Data ZakatFitrah Telah Dihapus",
      icon: "success",
      timer: 2000,
      button: false,
    }).then(() => {
      dispatch(GetZakatFitrahReceivedList(moment().iYear()));
    });
  }

  const columns = [
    {
      field: "id_peserta",
      headerName: "ID",
      flex: 0.3,
      renderCell: (params) => {
        return params.row.id_peserta;
      },
    },
    {
      field: "id_peserta_peserta",
      headerName: "Nama",
      flex: 0.8,
      valueGetter: (params, row) => {
        return row.id_peserta_peserta.name;
      },
    },
    {
      field: "kulak",
      headerName: "Kulak",
      flex: 0.4,
      editable: true,
      renderCell: (params) => {
        return params.row.amount_received.toString() === "0"
          ? "0"
          : params.row.amount_received / 3.25;
      },
    },
    {
      field: "amount_received",
      headerName: "Jumlah",
      flex: 0.4,
      editable: true,
      renderCell: (params) => {
        return params.row.amount_received + " Liter";
      },
    },
    {
      field: "is_zakat_received",
      headerName: "Status Dikumpulkan",
      flex: 0.7,
      renderCell: (params) => {
        return params.row.is_zakat_received.toString() === "1" ? (
          <Check style={{ color: "green" }} />
        ) : (
          <Clear style={{ color: "red" }} />
        );
      },
    },
    {
      field: "year_hijriah",
      headerName: "Tahun",
      flex: 0.4,
      renderCell: (params) => {
        return params.row.year_hijriah + " H";
      },
    },
    {
      field: "date_zakat_received",
      headerName: "Tanggal",
      flex: 0.7,
      renderCell: (params) => {
        return params.row.date_zakat_received;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div>
            <Visibility
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => {
                history.push("/admin/zakatfitrah/lihat/" + params.row.id);
              }}
            />
            <Edit
              style={{ color: "orange", cursor: "pointer" }}
              onClick={() => {
                history.push("/admin/zakatfitrah/ubah/" + params.row.id);
              }}
            />
            <Delete
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                dispatch(DeleteZakatFitrahReceived(params.row.id));
              }}
            />
          </div>
        );
      },
    },
  ];
  const rows = props.dataRow;
  return (
    <div>
      <DataGrid
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        loading={state.loading}
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={10}
      />
    </div>
  );
}

export default TableZakatFitrah;
