import Routes from '../../../config/Routes';
import './App.css';
function App() {
  return (
    <Routes>
    </Routes>
  );
}

export default App;
