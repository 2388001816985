import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import { Breadcrumbs, Grid } from "@material-ui/core";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import AdminTemplate from "../../templates/AdminTemplate";
import FormQurbanSent from "../../../components/moleculs/FormQurbanSent";
import { GetDetailQurbanSent } from "../../../config/redux/services";

function EditQurbanSent() {
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (params.id) {
      dispatch(GetDetailQurbanSent(params.id));
    }
  }, [dispatch, params.id]);
  return (
    <AdminTemplate>
      <div>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <NavLink color="secondary" to="/admin/qurbansent">
                  Peserta Penerima Qurban
                </NavLink>
                <NavLink color="secondary" to="/admin/qurbansent/ubah/:id">
                  Ubah Peserta Penerima Qurban
                </NavLink>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <FormQurbanSent />
            </Grid>
          </Grid>
        </Container>
      </div>
    </AdminTemplate>
  );
}

export default EditQurbanSent;
